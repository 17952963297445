<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('email-block-modal', {id: 0},shown,{})"
                        variant="primary"
                        class="mb-2 mr-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.email_block.label.id') }}</b-th>
                            <b-th>{{ $t('settings.email_block.label.name') }}</b-th>
                            <b-th>{{ $t('settings.email_block.label.user') }}</b-th>
                            <b-th>&nbsp;</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="block in blocks" :key="block.id">
                            <b-td>{{ block.id }}</b-td>
                            <b-td>{{ block.name }}</b-td>
                            <b-td>
                                <div v-if="block.user">{{ block.user.email }}</div>
                                <CBadge v-else color="success">Global</CBadge>
                            </b-td>
                            <b-td>
                                <b-button
                                    @click="$root.$children[0].openModal('email-block-modal', {id: block.id},shown,{})"
                                    size="sm"
                                    variant="primary"
                                    class="mr-2"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>

                                <b-button @click="deleteBlock(block.id)"
                                          size="sm"
                                          variant="danger"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Settings-email-blocks',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            blocks: [],
        }
    },
    methods: {
        ...mapGetters('EmailTemplate', ['getEmailBlocks']),
        shown() {
            const blocksFetch = this.$store.dispatch('EmailTemplate/fetchBlocks')

            Promise.all([blocksFetch])
                .then(() => {
                    this.blocks = this.getEmailBlocks()
                })
                .finally(() => {
                    this.loading = false
                })
            this.$root.afterSettingsUpdate()
        },
        deleteBlock(id) {
            this.$removeConfirm('EmailTemplate/deleteBlock', id, this.shown)
        }
    },
    mounted() {
        this.shown()
    }
}
</script>